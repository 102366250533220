import React, { useEffect } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';
import Icon from './Icon';
import { set } from 'date-fns';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Button from './Button';

const AccordionBase = styled.div<{ fullWidth?: boolean }>`
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  width: ${props => (props.fullWidth ? '100%' : '380px')};
`;

const AccordionHeaderBase = styled.div<{ selected?: boolean }>`
  display: flex;
  background-color: rgb(239, 244, 250);
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 20px;
  border-bottom-left-radius: ${props => (props.selected ? '0px' : '20px')};
  border-bottom-right-radius: ${props => (props.selected ? '0px' : '20px')};
  margin-top: 10px;
  font-family: Roboto, sans-serif;
  align-items: center;
  cursor: pointer;
  padding-bottom: 15px;
  & label {
    margin-top: -20px;
  }
`;

const AccordionHeaderLabel = styled.div`
  width: 100%;
  margin-left: 10px;
  font-weight: bold;
  margin-top: 9px;
  display: contents;
`;

const AccordionHeaderIcons = styled.div`
  display: flex;
  padding-left: 10px;
  width: 0px;
`;

const AccordionBodyBase = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  transition: max-height 0.1s;
  font-family: Roboto, sans-serif;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
  padding: ${props => (props.selected ? '20px' : '0px')};
  border-right: #eee solid ${props => (props.selected ? '1px' : '0px')};
  border-left: #eee solid ${props => (props.selected ? '1px' : '0px')};
  border-bottom: #eee solid ${props => (props.selected ? '1px' : '0px')};
  line-height: 1.4;
`;

export interface AccordionItemProps {
  objValue: any;
  icons?: string[];
  content?: any;
}

interface AccordionProps {
  values: AccordionItemProps[];
  onChange?: (value: AccordionItemProps) => void;
  defaultValue?: any;
  fullWidth?: boolean;
}

const Accordion = ({ values, onChange, defaultValue, fullWidth }: AccordionProps) => {
  const [elValue, setElValue] = React.useState<any | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setElValue(defaultValue);
    }
  });

  if (elValue === null) {
    return null;
  }

  return (
    <>
      <AccordionBase className="SeminargoAccordion" fullWidth={fullWidth}>
        {values.map((value, index) => (
          <div key={'accordion_' + value.objValue.sku} className="SeminargoAccordionItem">
            <AccordionHeaderBase
              tabIndex={1}
              aria-label={value.objValue.header + ' ' + value.content}
              selected={value.content && value.objValue.sku === elValue.sku}
              className="SeminargoAccordionHeader"
              onClick={() => {
                setElValue(value);
                onChange && onChange(value.objValue);
              }}
            >
              <Radio id={value.objValue.sku} aria-label={value.objValue.header} type="radio" name={value.objValue.sku} value={value.objValue.sku} checked={value.objValue.sku === elValue.sku} label={<><AccordionHeaderLabel className="SeminargoAccordionHeaderLabel">{value.objValue.header}</AccordionHeaderLabel></>} readOnly />
              
              <AccordionHeaderIcons className="SeminargoAccordionHeaderIcons">
                {value.icons?.map(icon => (
                  <div style={{ marginRight: 10 }}>
                    <Icon name={icon} />
                  </div>
                ))}
              </AccordionHeaderIcons>
            </AccordionHeaderBase>
            <AccordionBodyBase
              selected={value.content && value.objValue.sku === elValue.sku}
              style={{
                maxHeight: value.objValue.sku === elValue.sku ? 1000 : 0,
              }}
            >
              {value.content}
            </AccordionBodyBase>
          </div>
        ))}
      </AccordionBase>
    </>
  );
};

export default Accordion;
