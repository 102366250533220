import React, { useEffect } from 'react';
import styled from 'styled-components';
import { approveOfferVersion, getOfferDetails, rejectOfferVersion } from '../api/api';
import { set } from 'lodash';
import Button from './Button';
import FullScreenOverlay from './FullScreenOverlay';
import Checkbox from './Checkbox';
import { OtelManager } from '../api/otel';

const BaseOfferOverlayContent = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  text-align: center;

  & > iframe {
    max-width: 750px;
    border: 1px solid #ccc;
  }
`;

const BaseOfferOverlayContentHeader = styled.div`
  width: 100%;
  height: 70px;
  text-align: center;

  & button {
    margin-right: 5px;
  }
`;

interface OfferOverlayProps {
  hotelRef?: string;
  offerVersionId?: number;
  color: string;
  overflowApproved?: boolean | null;
  previousFocusRef?: React.RefObject<any>;
}

const OfferOverlay = ({ hotelRef, offerVersionId, color, overflowApproved, previousFocusRef }: OfferOverlayProps) => {
  const [offerDetails, setOfferDetails] = React.useState<any | null>(null);
  const [resultMsg, setResultMsg] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [showChanges, setShowChanges] = React.useState<boolean>(true);

  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (!hotelRef || !offerVersionId) return;
    getOfferDetails({ hotelRef, offerVersionId })
      .then(res => {
        setOfferDetails(res);
      })
      .catch(err => {
        setError(err);
      });
  }, []);

  const getResultMsg = () => {
    if (offerDetails) {
      if (error) {
        return <p style={{ color: 'red', fontFamily: '"Roboto", sans-serif' }}>Es ist ein Fehler aufgetreten: {error}</p>;
      }
      if (resultMsg) {
        return resultMsg;
      }
      if (overflowApproved === false) {
        return 'Ihre Daten werden nicht an seminargo weitergegeben! Vielen Dank für Ihr Interesse!';
      }
      if (overflowApproved) {
        return 'Ihre Daten wurden an seminargo weitergegeben! Vielen Dank für Ihr Interesse!';
      }
      return 'Es wurde bereits eine Entscheidung getroffen!';
    } else {
      return '';
    }
  };

  return (
    <FullScreenOverlay previousFocusRef={previousFocusRef}>
      <BaseOfferOverlayContent>
        <BaseOfferOverlayContentHeader>
          {!error && offerDetails && offerDetails.actionRequired ? (
            <div
              style={{
                display: 'flex',
                justifyContent: offerDetails.htmlWithChanges ? 'space-between' : 'center',
                maxWidth: 750,
                margin: 'auto',
                height: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontWeight: 'bold',
                  fontFamily: '"Roboto", sans-serif',
                  marginBottom: 20,
                }}
              >
                {loading && <p style={{ fontFamily: '"Roboto", sans-serif' }}>Bitte warten ...</p>}
                {!loading && <><Button
                  filled={true}
                  color={color}
                  onClick={async () => {
                    setLoading(true);
                    const result = await approveOfferVersion(offerVersionId!);
                    if (result) {
                      setResultMsg('Sie haben das Angebot akzeptiert!');
                      if (!hotelRef || !offerVersionId) return;
                      getOfferDetails({ hotelRef, offerVersionId })
                        .then(res => {
                          OtelManager.getInstance().widgetSetCurrentView('form-offer-accepted');
                          setOfferDetails(res);
                          setLoading(false);
                        })
                        .catch(err => {
                          setError(err);
                          setLoading(false);
                        });
                    }
                  }}
                >
                  Angebot annehmen
                </Button>
                <Button
                  filled={false}
                  color={color}
                  onClick={async () => {
                    const result = await rejectOfferVersion(offerVersionId!);
                    if (result) {
                      setResultMsg('Sie haben das Angebot abgelehnt!');
                      if (!hotelRef || !offerVersionId) return;
                      getOfferDetails({ hotelRef, offerVersionId })
                        .then(res => {
                          OtelManager.getInstance().widgetSetCurrentView('form-offer-rejected');
                          setOfferDetails(res);
                          setLoading(false);
                        })
                        .catch(err => {
                          setError(err);
                          setLoading(false);
                        });
                    }
                  }}
                >
                  Angebot ablehnen
                </Button></>}
              </div>
              {offerDetails.htmlWithChanges ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontWeight: 'bold',
                    fontFamily: '"Roboto", sans-serif',
                    marginBottom: 20,
                  }}
                >
                  <Checkbox
                    flexLabel={false}
                    name="showChanges"
                    label="Änderungen vom Hotel anzeigen"
                    aria-label='Änderungen vom Hotel anzeigen'
                    checked={showChanges}
                    onChange={() => setShowChanges(!showChanges)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div style={{ paddingTop: 20, fontWeight: 'bold', fontFamily: '"Roboto", sans-serif', marginBottom: 20 }}>{getResultMsg()}</div>
          )}
        </BaseOfferOverlayContentHeader>
        {!hotelRef || !offerVersionId ? <>Error</> : <></>}
        {!offerDetails ? (
          <div style={{ fontWeight: 'bold', fontFamily: '"Roboto", sans-serif' }}>Bitte warten ...</div>
        ) : (
          <iframe
            srcDoc={offerDetails.htmlWithChanges && showChanges ? offerDetails.htmlWithChanges : offerDetails.html}
            width="100%"
            height="100%"
          ></iframe>
        )}
      </BaseOfferOverlayContent>
    </FullScreenOverlay>
  );
};

export default OfferOverlay;
