import React, { useEffect, useRef } from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import { approveOfferVersion, getOfferDetails, rejectOfferVersion } from '../api/api';
import { set } from 'lodash';
import Button from './Button';
import { createShadow, shadowRootFocusListener } from '../helper';
import { createPortal } from 'react-dom';

const BaseFullScreenOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  z-index: 99999;
  top: 0;
  left: 0;
`;

interface FullScreenOverlayProps {
  children?: React.ReactNode;
  closeFunc?: () => void;
  setPopupShow?: (show: string | null) => void;
  previousFocusRef?: React.RefObject<any>;
}

const FullScreenOverlay = ({ children, closeFunc, setPopupShow, previousFocusRef }: FullScreenOverlayProps) => {
  const [shadow, setShadow] = React.useState<any | null>(null);
  const [overflow, setOverflow] = React.useState<string | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const shadow = createShadow(document.body);
    setShadow(shadow);
    if (document.body.style.overflow) {
      setOverflow(document.body.style.overflow);
    }
    document.body.style.overflow = 'hidden';

    let interval: any;

    setTimeout(() => {
      ref.current?.focus();
      ref.current?.addEventListener('focus', shadowRootFocusListener, true);
    }, 200);

    return () => {
      clearInterval(interval);
      if (overflow) {
        document.body.style.overflow = overflow;
      } else {
        document.body.style.removeProperty('overflow');
      }
    };
  }, []);

  return (
    <>
      {shadow &&
        createPortal(
          <StyleSheetManager target={shadow}>
            <div style={{ all: 'initial' }}>
            <BaseFullScreenOverlay
                tabIndex={1}
                ref={ref}
                onKeyUp={e => {
                  if (e.key === 'Escape') {
                    previousFocusRef?.current?.focus();
                    closeFunc && closeFunc();
                  }
                }}
              >
                {children}
              </BaseFullScreenOverlay>
            </div>
          </StyleSheetManager>,
          shadow,
        )}
    </>
  );
};

export default FullScreenOverlay;
